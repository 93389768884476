style {
    .footer-wrapper {
        background: var(--gray-0);
        padding: var(--size-5);
        display: flex;
        flex-wrap: wrap;
    }

    .footer-copyright {
        flex: 1;
    }
}

div.footer-wrapper
    div.footer-copyright
        p
            -- Copyright 2022 ${" "}
            a href="https://hunterstratton.com" -- Hunter Stratton
    div.footer-links
        a href="https://github.com/Paradxil/tarry"
            feather-icon icon="github"