style {
    .quote-wrapper {
        padding: var(--size-8) var(--size-fluid-4);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .quote-text {
        position: relative;
        font-style: italic;
        font-size: var(--font-size-4);
        z-index: 2;
    }
}

div.quote-wrapper
    p.quote-text -- It is the time you have wasted for your rose that makes your rose so important.
    p.quote-source -- Antoine de Saint-Exupéry, The Little Prince