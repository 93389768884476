style {
    .hero-wrapper {
        padding: var(--size-10) var(--size-fluid-4);
        display: flex;
        place-content: center;
        overflow: hidden;
        background-image: linear-gradient(45deg, var(--blue-5), var(--cyan-4));
    }

    .hero-left-wrapper {
        z-index: 1;
    }

    .hero-content-wrapper {
        padding: var(--size-5);
        background: white;
        box-shadow: var(--shadow-4);
        border-radius: var(--radius-2);
        max-width: var(--size-content-2);
        height: fit-content;
    }

    .hero-header {
        font-size: 4.75em;
        background-image: linear-gradient(45deg, var(--blue-6), var(--cyan-6));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .hero-subtitle {
        margin-left: var(--size-3);
        color: var(--yellow-8);
        transform: translateY(-20px);
        font-size: var(--font-size-5);
    }

    .hero-content {
        margin-left: var(--size-3);
        transform: translateY(-10px);
        font-size: var(--font-size-3);
    }

    .hero-image-wrapper {
        max-width: var(--size-content-3);
        min-width: var(--size-content-2);
        transform: translateX(-40px) translateY(-30px);
    }

    .hero-image-wrapper > img {
        width: 100%;
        border-radius: var(--radius-2);
        box-shadow: var(--shadow-3);
    }

    .hero-actions {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .hero-action {
        background: var(--blue-7);
        padding: var(--size-2) var(--size-3);
        display: block;
        text-align: center;
        color: white;
        border-radius: var(--radius-2);
        width: fit-content;
        font-size: var(--font-size-3);
        box-shadow: var(--shadow-4);
        transition: background 0.25s, box-shadow 0.25s;
    }

    .hero-action:hover {
        background: var(--blue-6);
        box-shadow: var(--shadow-2);
    }

    .hero-secondary-action {
        background: var(--yellow-7);
    }

    .hero-secondary-action:hover {
        background: var(--yellow-6);
    }

    @media only screen and (width <= 900px) {
        .hero-wrapper {
            place-content: flex-start !important;
        }
    }
}

div.hero-wrapper
    div.hero-left-wrapper
        div.hero-content-wrapper
            h1.hero-header -- ${input.title}
            h2.hero-subtitle -- ${input.subtitle}
            p.hero-content -- ${input.content}
        if(input.primaryaction || input.secondaryaction)
            div.hero-actions
                if(input.primaryaction)
                    a.hero-action.hero-primary-action href=input.primaryaction.link -- ${input.primaryaction.text}
                if(input.secondaryaction)
                    a.hero-action.hero-secondary-action href=input.secondaryaction.link -- ${input.secondaryaction.text}

    if(input.image)
        div.hero-image-wrapper
            img src=input.image